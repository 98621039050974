/* CARD */

.card{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border: 1px solid #808080; 
    border-radius: 0;
    background-color:#f2f4f8;
    height: 100%;
    
}

.card-body{
    padding: 12px 12px 0px 12px!important;
} 
   
.card-body-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    text-align: center;
    background-color: #2F5597;
    color: #ffffff !important;
    border-radius: 3px !important;
}

.card-body-text{
    font-size: 12px;
    font-weight: bold;
}

.card-body-text p{
    margin-bottom: 1rem;
}

.card-body-text a {
    color: #4472c4;
    font-weight: bold;
    font-size: 12px;
}

.card-body-footer{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    margin: 2px 5px 10px 5px!important;
}

.card-links {
    display: flex;
    background-color: #ffffff;
    padding: 2px;
    border: 1px solid #2f5597;
    border-radius: 3px;
    font-size: 11px;
    text-align: center;
    font-weight: bold; 
    width: calc(50% - 10px);
    height: 50px;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    margin-right: 6px;
  }

  .card-links:only-child {
    width: 95%;
  }

  .card-links:last-child {
    margin-right: 0;
  }

.card-links:hover{
    transition: 0.6s;
    cursor: pointer;
    background-color: #2f5597; 
    color:#ffffff;
    text-decoration: none !important;
}