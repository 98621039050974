.accordionTitle{
  font-size: 18px !important;
  color: #fff !important;
  font-style: italic !important;
  font-weight: 600 !important;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding:1%;
  border:none;
}

 .accordionWrapper {
    width: 100%;
  }
  
  .accordionContentOpen {
      display: block;
      transition: max-height 0.2s ease-in-out;
  }
  .accordionContentClose {
    display: none;
  }
  
  .dropdownIcon {
    transition: transform 0.3s ease;
  }

  .accordionTitle:before {
    display:none;
  }

  
  .accordionContentOpen .main-content {
    max-height:1600px;
    overflow: auto;
  }

  .accordionContentOpen .side-content {
    height: 500px; 
    overflow: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 3%;
  }
  .faqAccordion .accordionTitle{
    font-size: 0.9rem !important;
  }
    /* .accordionContent {
      width: auto;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;

    } */
    
    /* .accordionContent.accordionContentOpen {
      max-height: 1500px; 
    } */