/*
 * Cookie-Widget CSS
 *
 */
.prefCentreDiv {
  border-style: outset;
  border-width: 2px;
  padding: 11px;
  margin: 9px;
  border-color: rgba(54, 52, 52, 0.33);
  background-color: lightgrey;
  background-image: -webkit-linear-gradient(
    0,
    rgba(0, 0, 0, 0.08) 2%,
    rgba(0, 1, 15, 0.32) 98%
  );
  background-image: -moz-linear-gradient(
    0,
    rgba(0, 0, 0, 0.08) 2%,
    rgba(0, 1, 15, 0.32) 98%
  );
  background-image: -o-linear-gradient(
    0,
    rgba(0, 0, 0, 0.08) 2%,
    rgba(0, 1, 15, 0.32) 98%
  );
  background-image: -ms-linear-gradient(
    0,
    rgba(0, 0, 0, 0.08) 2%,
    rgba(0, 1, 15, 0.32) 98%
  );
}

.prefCentreCheckbox {
  text-align: right;
}

.prefCentreLabel {
  width: auto;
}

body.syrenis-cookie-module {
  overflow: scroll;
}
body.syrenis-cookie-module h1 {
  font-size: 32px !important;
  line-height: 48px !important;
}

/* Main Widget */
.syrenis-cookie-widget {
  font-family: Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size-adjust: none;
  /* Main popup */
  /* Toggle switches */
  /* Footer Content (currently not used) */
  /* Button */
}
.syrenis-cookie-widget .cf:after,
.syrenis-cookie-widget .cf:before {
  display: table;
  content: "";
}
.syrenis-cookie-widget .cf:after {
  clear: both;
}
.syrenis-cookie-widget .scroll {
  overflow-y: hidden;
}
.syrenis-cookie-widget .focusIndicator {
  outline: 1px dotted #212121 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.syrenis-cookie-widget .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(33, 33, 33, 0.75) !important;
  transition: opacity 50ms;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  overflow-y: auto;
}
.syrenis-cookie-widget span.status,
.syrenis-cookie-widget .footer_extra {
  display: none !important;
}
.syrenis-cookie-widget .popup {
  margin: 16px 0 16px 0 !important;
  padding: 0 !important;
  transition: 0.5s transform ease !important;
  max-height: calc(100vh - 32px) !important;
  /* Close button */
  /* Logo image */
  /* Content before the cookies */
  /* individual cookie sections */
  /* Save preferences button */
}
@media (min-width: 850px) {
  .syrenis-cookie-widget .popup {
    margin: 48px 0 !important;
    width: 90% !important;
  }
}
.syrenis-cookie-widget .popup .verti-scroll {
  max-height: calc(100vh - 24px);
  background: #fff !important;
  border-radius: 4px !important;
  padding: 0 !important;
  position: relative;
}
@media (min-width: 850px) {
  .syrenis-cookie-widget .popup .verti-scroll {
    max-height: calc(100vh - 96px);
  }
}
.syrenis-cookie-widget .popup .close {
  z-index: 99999;
  display: block;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  margin: 16px;
  font-weight: 100 !important;
  line-height: 1rem;
  opacity: 1 !important;
  visibility: visible !important;
}
.syrenis-cookie-widget .popup .close:hover {
  color: #eee !important;
}
.syrenis-cookie-widget .popup .close:focus {
  outline: 0 !important;
  text-shadow: 0 0 2px 2px rgba(158, 158, 158, 0.4);
}
.syrenis-cookie-widget .popup .close:focus-visible {
  outline: 1px dotted #212121 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.syrenis-cookie-widget .popup img {
  width: 88px !important;
  padding-bottom: 0 !important;
}
.syrenis-cookie-widget .popup #header-image {
  margin: 0 !important;
  display: flex;
  justify-content: center;
}
.syrenis-cookie-widget .popup #header-image a {
  margin-top: 56px;
  display: inline-block;
}
.syrenis-cookie-widget .popup #header-content {
  margin-top: 0 !important;
  padding: 0 50px 10px !important;
}
.syrenis-cookie-widget .popup .content {
  position: relative;
  padding: 48px 48px 24px 48px !important;
  color: inherit !important;
  border: none !important;
  margin-bottom: 0 !important;
}
.syrenis-cookie-widget .popup .content h1 {
  font: 400 2rem/3rem Arial, sans-serif;
  margin-bottom: 1.5rem;
}
.syrenis-cookie-widget .popup .content p span i {
  font-style: inherit;
  font-size: 100%;
}
.syrenis-cookie-widget .popup .content a {
  color: #4472c4 !important;
}
.syrenis-cookie-widget .popup .content .on_off_switch {
  font: 700 1rem/ 24px Arial, sans-serif;
  text-align: right;
  font-weight: 700;
  color: #4472c4;
  padding-bottom: 0;
}
.syrenis-cookie-widget .popup .content .on_off_switch .accept-all-span {
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
}
.syrenis-cookie-widget .popup .content .on_off_switch .switch_inner_switch {
  margin-left: 30px;
  top: 0;
}
.syrenis-cookie-widget .popup .content .alwaysActive {
  display: block;
  position: absolute;
  width: 40px;
  right: 60px;
  z-index: 99999;
  top: calc(100% + 50px);
}
.syrenis-cookie-widget .popup .content .alwaysActive span {
  color: #4472c4;
  font-weight: 500;
}
.syrenis-cookie-widget .popup #strictly-necessary {
  border-top: 1px solid #dedede;
  margin-top: 0;
  padding-top: 24px;
}
.syrenis-cookie-widget .popup .body_content {
  padding: 0;
  line-height: inherit;
  color: #212121;
  padding-bottom: 0px !important;
  position: relative;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #dedede !important;
  border-radius: 0;
  margin: 24px 48px 24px 48px;
  background: white;
  /* view cookies content */
}
.syrenis-cookie-widget .popup .body_content p,
.syrenis-cookie-widget .popup .body_content h3,
.syrenis-cookie-widget .popup .body_content h4 {
  max-width: calc(100% - 80px);
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 1em !important;
  width: 100%;
}
.syrenis-cookie-widget .popup .body_content p {
  font: 400 14px / 1.5rem arial, sans-serif !important;
  font-size: 100% !important;
  margin: 0 !important;
  padding-left: 0;
}
.syrenis-cookie-widget .popup .body_content h3 {
  font: 700 1rem/ 24px Arial, sans-serif;
  max-width: calc(100% - 80px);
  margin-bottom: 1rem;
  color: #212121;
}
.syrenis-cookie-widget .popup .body_content .toggle-butn-box {
  padding: 0 !important;
  background: none;
  border: none;
  color: #4472c4 !important;
  font: 400 0.75rem / 1rem Arial, sans-serif;
  font-weight: bold;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .viw-cont {
  font-weight: bold !important;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .hid-cont {
  font-weight: bold !important;
}
.syrenis-cookie-widget
  .popup
  .body_content
  button.toggle-butn-box
  span.hid-cont {
  display: none;
}
.syrenis-cookie-widget
  .popup
  .body_content
  button.toggle-butn-box.down-arrowbox
  .viw-cont {
  display: none;
}
.syrenis-cookie-widget
  .popup
  .body_content
  button.toggle-butn-box.down-arrowbox
  .hid-cont {
  display: block;
}
.syrenis-cookie-widget .popup .body_content .main-wraper {
  clear: both;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0px;
  width: 100%;
}
.syrenis-cookie-widget .popup .body_content .main-wraper button {
  height: 100% !important;
  clear: both;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .viw-cont,
.syrenis-cookie-widget .popup .body_content .main-wraper .hid-cont {
  position: relative;
  padding-left: 18px;
  font-weight: 500;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .viw-cont i,
.syrenis-cookie-widget .popup .body_content .main-wraper .hid-cont i {
  position: absolute;
  left: 0;
  font-size: 18px !important;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .viw-cont i {
  top: -6px !important;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .hid-cont i {
  bottom: -3px !important;
}
.syrenis-cookie-widget .popup .body_content .main-wraper .target {
  border: 0px solid #ccc;
  padding-left: 24px;
}
.syrenis-cookie-widget
  .popup
  .body_content
  .main-wraper
  .target
  .content_title {
  position: relative;
}
.syrenis-cookie-widget
  .popup
  .body_content
  .main-wraper
  .target
  .content_title:last-child {
  padding-bottom: 0 !important;
}
.syrenis-cookie-widget
  .popup
  .body_content
  .main-wraper
  .target
  .content_title {
  padding-bottom: 24px !important;
}
.syrenis-cookie-widget
  .popup
  .body_content
  .main-wraper
  .target
  .content_title
  h4 {
  font: 700 1rem/ 24px Arial, sans-serif;
  color: #212121;
  margin-bottom: 1rem;
  padding-top: 24px;
}
.syrenis-cookie-widget
  .popup
  .body_content
  .main-wraper
  .target
  .content_title
  p {
  font: 400 0.75rem/1rem Arial, sans-serif;
  color: #212121;
  padding: 0;
}
.syrenis-cookie-widget .popup .save {
  display: block;
  margin: 0 auto;
  background-color: #4472c4;
  border: 2px solid transparent;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.syrenis-cookie-widget .popup .save:hover,
.syrenis-cookie-widget .popup .save.hover {
  background-color: colorshift(#4472c4, 700);
}
.syrenis-cookie-widget .popup .save:focus {
  outline-offset: 2px;
  box-shadow: 0 0 2px 2px rgba(213, 43, 30, 0.4);
}
.syrenis-cookie-widget .popup .save:active,
.syrenis-cookie-widget .popup .save.active {
  background-color: colorshift(#4472c4, 900);
}
.syrenis-cookie-widget .switch {
  margin-left: 0 !important;
}
.syrenis-cookie-widget .switch,
.syrenis-cookie-widget .inner_switch,
.syrenis-cookie-widget .switch_inner_switch,
.syrenis-cookie-widget .radiobuttons {
  position: relative;
  display: inline-block;
  width: 48px !important;
  height: 24px !important;
  right: 0;
}
.syrenis-cookie-widget .switch .slider,
.syrenis-cookie-widget .switch .slider:before,
.syrenis-cookie-widget .inner_switch .slider,
.syrenis-cookie-widget .inner_switch .slider:before,
.syrenis-cookie-widget .switch_inner_switch .slider,
.syrenis-cookie-widget .switch_inner_switch .slider:before,
.syrenis-cookie-widget .radiobuttons .slider,
.syrenis-cookie-widget .radiobuttons .slider:before {
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.syrenis-cookie-widget .switch .slider,
.syrenis-cookie-widget .inner_switch .slider,
.syrenis-cookie-widget .switch_inner_switch .slider,
.syrenis-cookie-widget .radiobuttons .slider {
  cursor: pointer;
  height: 24px;
  width: 48px;
  left: 0;
  background-color: #9e9e9e !important;
  border-radius: 100px;
}
.syrenis-cookie-widget .switch .slider:before,
.syrenis-cookie-widget .inner_switch .slider:before,
.syrenis-cookie-widget .switch_inner_switch .slider:before,
.syrenis-cookie-widget .radiobuttons .slider:before {
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: #fff;
}
.syrenis-cookie-widget .body_content .switch,
.syrenis-cookie-widget .inner_switch,
.syrenis-cookie-widget .radiobuttons {
  float: right;
  position: absolute;
  top: 20px !important;
}
.syrenis-cookie-widget .switch input,
.syrenis-cookie-widget .inner_switch input,
.syrenis-cookie-widget .switch_inner_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.syrenis-cookie-widget input:checked + .slider {
  background-color: #4472c4 !important;
}
.syrenis-cookie-widget input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.syrenis-cookie-widget input:checked + .slider:before {
  -webkit-transform: translateX(24px) !important;
  -ms-transform: translateX(24px) !important;
  transform: translateX(24px) !important;
}
.syrenis-cookie-widget
  .body_content
  > .switch.checked-class
  span.slider:before {
  -webkit-transform: translateX(24px) !important;
  -ms-transform: translateX(24px) !important;
  transform: translateX(24px) !important;
}
.syrenis-cookie-widget #footer-content {
  padding: 0 48px 10px 48px;
}
.syrenis-cookie-widget #footer-content p {
  margin: 0;
}
.syrenis-cookie-widget #footer-content a {
  color: #0078a3;
}
.syrenis-cookie-widget button {
  padding: 6px 30px !important;
  transition: 0.25s ease all;
  cursor: pointer;
}
.syrenis-cookie-widget button:focus {
  outline: 0 !important;
}
.syrenis-cookie-widget button:focus-visible {
  outline: 1px dotted #212121 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
.syrenis-cookie-widget button:active,
.syrenis-cookie-widget button.active {
  box-shadow: none !important;
}

.syrenis-cookie-widget #reject-new,
.syrenis-cookie-widget #accept-new {
  border-color: #4472c4;
  color: #fff;
  background-color: #4472c4;
}

.syrenis-cookie-widget #reject-new {
  height: 70px !important;
  font-size: 1.125rem;
  font-weight: 700 !important;
  border-radius: 6px;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-350px);
  width: 200px;
}

.syrenis-cookie-widget #accept-new {
  height: 70px !important;
  font-size: 1.125rem;
  font-weight: 700 !important;
  border-radius: 6px;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(150px);
  width: 200px;
}

.syrenis-cookie-widget #save_preference {
  height: 70px !important;
  font-size: 1.125rem;
  font-weight: 700 !important;
  border-radius: 6px;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  background-color: #4472c4;
}
.syrenis-cookie-widget #save_preference:focus {
  outline: 0 !important;
}
.syrenis-cookie-widget #save_preference:focus-visible {
  outline: 1px dotted #212121 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: 2px;
}
@media (max-width: 400px) {
  .syrenis-cookie-widget #save_preference {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 900px) {
  .syrenis-cookie-widget #save_preference {
    margin-top: 100px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
  .syrenis-cookie-widget #accept-new {
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
  .syrenis-cookie-widget #reject-new {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 200px;
    width: 200px;
  }
  div#lastdiv-id {
    margin-top: 325px !important;
  }
}

.syrenis-cookie-widget .popup .close {
  display: none;
}
.syrenis-cookie-widget #footer-content p {
  margin-bottom: 20px;
}
.syrenis-cookie-widget #footer-content{
  display: none;
}
div#lastdiv-id {
  margin-top: 150px;
}
.syrenis-cookie-widget .popup .content {
  padding: 0px 50px 10px 50px !important;
}

.syrenis-cookie-widget a {
  text-decoration: underline;
  color: #0078a3;
}

.cookie-consent-message-container {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .cookie-consent-btn-container .button-base {
    line-height: 15px;
    font-size: 0.75rem;
    padding: 1px 6px;
  }
}

@media (max-width: 400px) {
  .syrenis-cookie-widget #save_preference {
    margin-bottom: 100px;
  }
}
@media (max-width: 460px) {
  .btn-accept {
    margin-left: 0px !important;
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .syrenis-cookie-widget .popup .content p span {
    font: 400 16px/24px Arial, sans-serif !important;
  }
}
