/*High Level Processes */
.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke:#1071e5;}.cls-1,.cls-6{fill:#fff;}.cls-2,.cls-3,.cls-7{fill:#1071e5;}.cls-8{fill:rgba(255,255,255,0);stroke:rgba(0,0,0,0);}.cls-3{stroke-width:.05px;}.cls-6,.cls-9,.cls-7,.cls-10,.cls-11{stroke-width:0px;}.cls-9{fill:#3a414a;}.cls-10{fill:#e81313;}.cls-11{fill:#008a0e;}.cls-4{fill:none;}.cls-5{fill:url(#linear-gradient);}

.cls-1-be1,.cls-2-be1,.cls-3-be1,.cls-4-be1,.cls-5-be1,.cls-6-be1,.cls-7-be1{stroke-width:0px;}.cls-1-be1,.cls-8-be1,.cls-9-be1{fill:#008573;}.cls-10-be1{fill:url(#linear-gradient-be2);}.cls-10-be1,.cls-11-be1{stroke:#1071e5;}.cls-8-be1,.cls-11-be1{stroke-width:.05px;}.cls-8-be1,.cls-9-be1,.cls-12-be1,.cls-13-be1{stroke:#008573;}.cls-14-be1{fill:url(#linear-gradient-be1);}.cls-14-be1,.cls-15-be1{stroke:rgba(0,0,0,0);}.cls-3-be1,.cls-13-be1{fill:none;}.cls-4-be1{fill:#333;}.cls-11-be1,.cls-7-be1{fill:#1071e5;}.cls-5-be1,.cls-12-be1{fill:#fff;}.cls-6-be1{fill:#3a414a;}.cls-15-be1{fill:url(#linear-gradient-be3);}

.cls-1-be1,.cls-2-be1,.cls-3-be1,.cls-4-be1,.cls-5-be1,.cls-6-be1,.cls-7-be1{stroke-width:0px;}.cls-1-be1,.cls-8-be1,.cls-9-be1{fill:#008573;}.cls-10-be1{fill:url(#linear-gradient-be2);}.cls-10-be1,.cls-11-be1{stroke:#1071e5;}.cls-8-be1,.cls-11-be1{stroke-width:.05px;}.cls-8-be1,.cls-9-be1,.cls-12-be1,.cls-13-be1{stroke:#008573;}.cls-14-be1{fill:url(#linear-gradient-be1);}.cls-14-be1,.cls-15-be1{stroke:rgba(0,0,0,0);}.cls-3-be1,.cls-13-be1{fill:none;}.cls-4-be1{fill:#333;}.cls-11-be1,.cls-7-be1{fill:#1071e5;}.cls-5-be1,.cls-12-be1{fill:#fff;}.cls-6-be1{fill:#3a414a;}.cls-15-be1{fill:url(#linear-gradient-be3);}

.cls-1-be3{stroke-width:.05px;}.cls-1-be3,.cls-2-be3{fill:#fcce14;}.cls-1-be3,.cls-2-be3,.cls-3-be3,.cls-4-be3{stroke:#fcce14;}.cls-5-be3,.cls-6-be3{stroke-width:0px;}.cls-6-be3{fill:#3a414a;}.cls-3-be3{fill:#fff;stroke-width:2px;}.cls-4-be3{fill:none;}

.cls-1p,.cls-2p,.cls-3p,.cls-4p,.cls-5p,.cls-6p,.cls-7p{stroke-width:0px;}.cls-1p,.cls-8p,.cls-9p{fill:#008573;}.cls-10p{fill:url(#linear-gradient-2p);}.cls-10p,.cls-11p{stroke:#1071e5;}.cls-8p,.cls-12p,.cls-9,.cls-13p,.cls-14p{stroke:#008573;}.cls-8p,.cls-11p{stroke-width:.05px;}.cls-15p{fill:url(#linear-gradient-p);}.cls-15p,.cls-16p{stroke:rgba(0,0,0,0);}.cls-3p,.cls-14p{fill:none;}.cls-4p{fill:#333;}.cls-12p{fill:url(#linear-gradient-4p);}.cls-11p,.cls-7p{fill:#1071e5;}.cls-5p,.cls-13p{fill:#fff;}.cls-6p{fill:#3a414a;}.cls-16p{fill:url(#linear-gradient-3p);}

.cls-1f{fill:url(#linear-gradient-f);}.cls-1f,.cls-2f,.cls-3f,.cls-4f,.cls-5f,.cls-6f{stroke:#e87203;}.cls-2f,.cls-7f{fill:#fff;}.cls-7f,.cls-8f{stroke-width:0px;}.cls-3f,.cls-6f{fill:#e87203;}.cls-4f{fill:none;}.cls-5f{fill:url(#linear-gradient-2f);}.cls-8f{fill:#3a414a;}.cls-6f{stroke-width:.05px;}

.cls-1y{stroke-width:.05px;}.cls-1y,.cls-2y,.cls-3y{fill:#fcce14;}.cls-1y,.cls-4y,.cls-5y,.cls-3y,.cls-6y,.cls-7y{stroke:#fcce14;}.cls-2y,.cls-8y,.cls-9y,.cls-10y,.cls-11y{stroke-width:0px;}.cls-12y{fill:url(#linear-gradient-y);}.cls-12y,.cls-13y,.cls-14y{stroke:rgba(0,0,0,0);}.cls-9y{fill:#333;}.cls-10y,.cls-6y{fill:#fff;}.cls-5y{fill:url(#linear-gradient-2y);}.cls-11y{fill:#3a414a;}.cls-7y{fill:none;}.cls-14y{fill:url(#linear-gradient-3y);}
.cls-1tp,.cls-2tp,.cls-3tp,.cls-4tp,.cls-5tp,.cls-6tp,.cls-7tp{stroke-width:0px;}.cls-1tp,.cls-8tp,.cls-9tp{fill:#008573;}.cls-10tp{fill:url(#linear-gradient-2tp);}.cls-10tp,.cls-11tp{stroke:#1071e5;}.cls-8tp,.cls-11tp{stroke-width:.05px;}.cls-8tp,.cls-9tp,.cls-12tp,.cls-13tp{stroke:#008573;}.cls-14tp{fill:url(#linear-gradient-tp);}.cls-14tp,.cls-15tp{stroke:rgba(0,0,0,0);}.cls-3tp,.cls-13tp{fill:none;}.cls-4tp{fill:#333;}.cls-11tp,.cls-7tp{fill:#1071e5;}.cls-5tp,.cls-12tp{fill:#fff;}.cls-6tp{fill:#3a414a;}.cls-15tp{fill:url(#linear-gradient-3tp);}
.cls-1yk{stroke-width:.05px;}.cls-1yk,.cls-2yk{fill:#fcce14;}.cls-1yk,.cls-3yk,.cls-4yk{stroke:#fcce14;}.cls-2yk,.cls-5yk,.cls-6yk,.cls-7yk,.cls-8yk{stroke-width:0px;}.cls-9yk{fill:rgba(255,255,255,0);stroke:rgba(0,0,0,0);}.cls-6yk{fill:#333;}.cls-7yk,.cls-4yk{fill:#fff;}.cls-8yk{fill:#3a414a;}.cls-4yk{stroke-width:2px;}.cls-10yk{fill:url(#linear-gradient-e);stroke:#1071e5;}
.cls-1il{fill:#fff;}.cls-1il,.cls-2il,.cls-3il,.cls-4il{stroke:#f77700;}.cls-5il{stroke-width:0px;}.cls-2il{fill:none;}.cls-3il{stroke-width:.05px;}.cls-3il,.cls-4il{fill:#f77700;}
.cls-1lq{stroke-width:.05px;}.cls-1lq,.cls-2lq{fill:#fcce14;}.cls-1lq,.cls-2lq,.cls-3lq,.cls-4lq{stroke:#fcce14;}.cls-5lq,.cls-6lq,.cls-7lq{stroke-width:0px;}.cls-6lq,.cls-3lq{fill:#fff;}.cls-7lq{fill:#3a414a;}.cls-3lq{stroke-width:2px;}.cls-4lq{fill:none;}.cls-8lq{fill:url(#linear-gradient-lq);stroke:#1071e5;}
.cls-1kl{fill:none;}.cls-1kl,.cls-2kl,.cls-3kl,.cls-4kl{stroke:#fc9432;}.cls-5kl,.cls-6kl,.cls-7kl{stroke-width:0px;}.cls-5kl,.cls-2kl,.cls-3kl{fill:#fc9432;}.cls-6kl{fill:#333;}.cls-2kl{stroke-width:.05px;}.cls-7kl{fill:#3a414a;}.cls-4kl{fill:#fff;}
.cls-1p0,.cls-2p0{fill:#2f5496;}.cls-1p0,.cls-2p0,.cls-3p0,.cls-4p0{stroke:#2f5496;}.cls-2p0{stroke-width:.05px;}.cls-3p0{fill:#fff;}.cls-5p0{fill:#3a414a;stroke-width:0px;}.cls-4p0{fill:none;}

.cls-1,.cls-2,.cls-3,.cls-4,.cls-5{stroke:#1071e5;}.cls-1,.cls-6{fill:#fff;}.cls-2,.cls-3,.cls-7{fill:#1071e5;}.cls-8{fill:rgba(255,255,255,0);stroke:rgba(0,0,0,0);}.cls-3{stroke-width:.05px;}.cls-6,.cls-9,.cls-7,.cls-10,.cls-11{stroke-width:0px;}.cls-9{fill:#3a414a;}.cls-10{fill:#e81313;}.cls-11{fill:#008a0e;}.cls-4{fill:none;}.cls-5{fill:url(#linear-gradient);}
.cls-1x,.cls-2x,.cls-3x,.cls-4x,.cls-5x{stroke:rgba(0,0,0,0);}.cls-1x,.cls-6x,.cls-7x{fill:#fff;}.cls-2x{fill:#3068bc;}.cls-8x{fill:url(#linear-gradient-3x);}.cls-8x,.cls-9x,.cls-10x,.cls-11x,.cls-7x,.cls-12x{stroke:#008573;}.cls-13x,.cls-14x,.cls-15x,.cls-6x,.cls-16x,.cls-17x{stroke-width:0px;}.cls-13x,.cls-9x,.cls-11x{fill:#008573;}.cls-18x{fill:url(#linear-gradient-2x);}.cls-18x,.cls-19x{stroke:#1071e5;}.cls-3x{fill:#d9d71a;}.cls-20x{fill:#979ea8;stroke:#000;}.cls-9x,.cls-19x{stroke-width:.05px;}.cls-4x{fill:#ff1300;}.cls-10x{fill:url(#linear-gradient-x);}.cls-15x{fill:#333;}.cls-5x{fill:#1f2066;}.cls-19x,.cls-17x{fill:#1071e5;}.cls-16x{fill:#3a414a;}.cls-12x{fill:none;}
.cls-1x,.cls-2x,.cls-3x,.cls-4x,.cls-5x{stroke:rgba(0,0,0,0);}.cls-1x,.cls-6x,.cls-7x{fill:#fff;}.cls-2x{fill:#3068bc;}.cls-8x{fill:url(#linear-gradient-3x);}.cls-8x,.cls-9x,.cls-10x,.cls-11x,.cls-7x,.cls-12x{stroke:#008573;}.cls-13x,.cls-14x,.cls-15x,.cls-6x,.cls-16x,.cls-17x{stroke-width:0px;}.cls-13x,.cls-9x,.cls-11x{fill:#008573;}.cls-18x{fill:url(#linear-gradient-2x);}.cls-18x,.cls-19x{stroke:#1071e5;}.cls-3x{fill:#d9d71a;}.cls-20x{fill:#979ea8;stroke:#000;}.cls-9x,.cls-19x{stroke-width:.05px;}.cls-4x{fill:#ff1300;}.cls-10x{fill:url(#linear-gradient-x);}.cls-15x{fill:#333;}.cls-5x{fill:#1f2066;}.cls-19x,.cls-17x{fill:#1071e5;}.cls-16x{fill:#3a414a;}.cls-12x{fill:none;}    
.cls-1be3{stroke-width:.05px;}.cls-1be3,.cls-2be3,.cls-3be3{fill:#fcce14;}.cls-1be3,.cls-3be3,.cls-4be3,.cls-5be3{stroke:#fcce14;}.cls-2be3,.cls-6be3,.cls-7be3,.cls-8be3{stroke-width:0px;}.cls-9be3{fill:rgba(255,255,255,0);stroke:rgba(0,0,0,0);}.cls-6be3{fill:#333;}.cls-7be3,.cls-4be3{fill:#fff;}.cls-8be3{fill:#3a414a;}.cls-4be3{stroke-width:2px;}.cls-5be3{fill:none;}.cls-10be3{fill:url(#linear-gradient-e);stroke:#1071e5;}

.cls-1-z,.cls-2-z,.cls-3-z,.cls-4-z,.cls-5-z{stroke:rgba(0,0,0,0);}.cls-1-z,.cls-6-z,.cls-7-z{fill:#fff;}.cls-8-z{fill:#1a3378;stroke:#1a3378;}.cls-2-z{fill:#3068bc;}.cls-9-z{fill:url(#linear-gradient-3ip);}.cls-9-z,.cls-10-z,.cls-11-z,.cls-12-z,.cls-7-z,.cls-13-z{stroke:#008573;}.cls-14-z,.cls-15-z,.cls-16-z,.cls-6-z,.cls-17-z,.cls-18-z,.cls-19-z{stroke-width:0px;}.cls-14-z,.cls-10-z,.cls-12-z{fill:#008573;}.cls-20-z{fill:url(#linear-gradient-2ip);}.cls-20-z,.cls-21-z{stroke:#1071e5;}.cls-3-z{fill:#d9d71a;}.cls-22-z{fill:#979ea8;stroke:#000;}.cls-10-z,.cls-21-z{stroke-width:.05px;}.cls-4-z{fill:#ff1300;}.cls-11-z{fill:url(#linear-gradient-1ip);}.cls-16-z{fill:#333;}.cls-5-z{fill:#1f2066;}.cls-21-z,.cls-18-z{fill:#1071e5;}.cls-17-z{fill:#3a414a;}.cls-13-z{fill:none;}.cls-19-z{fill:#e81313;}
.cls-1opo{fill:#1a3378;stroke:#1a3378;}.cls-2opo{fill:#979ea8;stroke:#000;}.cls-3opo,.cls-4opo{fill:#fff;}.cls-3opo,.cls-5opo,.cls-6opo,.cls-7opo{stroke:#e87203;}.cls-4opo,.cls-8opo,.cls-9opo{stroke-width:0px;}.cls-5opo,.cls-7opo{fill:#e87203;}.cls-6opo{fill:none;}.cls-8opo{fill:#3a414a;}.cls-9opo{fill:#e81313;}.cls-7opo{stroke-width:.05px;}
 

body{margin: 0px; padding: 0px;}
.h1-font {
    font-weight: normal !important;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.be_wrapper{ width: 100%; height: auto; margin: 0px auto;}
.header_strip {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);
    padding: 15px 0px;
    opacity: 0.9;
}

.cm_font{font-size: 13px;}

.comman_hd{font-weight: 600 !important;}

.bg_gradient_box{background-color: #A30F12; width: 100%; height: 10px;}
.bg_accordian{background-color: #fff;  border-radius: 10px; }
.comman_width{width: 55%; margin: auto; padding: 10px;}
.comman_width_d{width: 35%; margin: auto;}
.comman_width_de{width: 100%; margin: auto;}
.comman_width_e{width: 55%; margin: auto;}

.brief{  border-radius: 5px; border-right: 3px solid #fff;}
.planning{  border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.approval{   border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.material{   border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.widthdrawal{  border-radius: 5px; border-right: 3px solid #fff;}


.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  background: #ebf5fe;
  
}

.bg-footer {
  background: #4f4f4f !important;
  color:#fff;
}

.comman_accordian{font-size: 18px !important;
  color: #fff !important;
  font-style: italic !important;
  font-weight: 600 !important;
}

.accordion-body {

    padding-top: 2px !important;
    padding-bottom: 15px !important;
}
.h5, h5 {
    font-size: 1rem !important;
}

.ul_padding{padding-left: 1rem;}



.p_font{ font-size: 14px;}


svg path{ strocke:#eee;
 stroke-width: .25;}

svg path:hover{transition: 0.6s;
               cursor: pointer;
fill:#edeef0; color: #000000;}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.accordion-button:focus {
    box-shadow: none !important;
}

.comman_font_pharasize{ font-size: 11px;}

.comman_border-radius{border-radius: 5px;}
.widthdrawal_content{background-color: #f7f7f7;}
.widthdrawal_content_2{background-color: #f0f1f1;}

.btm_footer{ }
.btm_footer ul{ margin: 0px; padding: 0px; list-style: none;}
.btm_footer ul li{ float: left; padding: 0px 0px 0px 50px; text-decoration: none !important; font-weight: normal;}
.btm_footer ul li a{ float: left; padding: 0px 10px 0px 10px; text-decoration: none !important; font-weight: normal;}
.btm_footer ul li a:hover{ float: left; padding: 0px 10px 0px 10px; text-decoration: none !important; font-weight: normal;}


.tab div {
    display: none;
}

.tab div:target {
    display: block;
  
}


.tab-2 div {
    display: none;
}

.tab-2 div:target {
    display: block;
	height: 30px;
  
}



.pop{font-size: 12px; background-color: #cfe2ff; padding: 5px 10px 5px 10px; border-radius: 5px;}
.pop ul{margin: 0px; padding: 0px; list-style: none;}
.pop ul li{}



/*popup*/
.overlay_flight_traveldil {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 1s;
  visibility: hidden;
  opacity: 0;
}
.overlay_flight_traveldil:target {
  visibility: visible;
  opacity: 1;
}
.popup_flight_travlDil {
  margin: 215px 0px 0px 0px;

  padding: 20px 20px 10px 20px;
 /* background: #b8f5ed;*/
  
background-image: linear-gradient(135deg, #fdfcfb 10%, #e2d1c3 100%);
  border-radius: 5px;
  width: 20%;
  position: relative;
  transition: all 2s ease-in-out;
  border-left:5px solid #d62c27;	
}
.popup_flight_travlDil .close_flight_travelDl {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none !important;
  color: #333;
}
.popup_flight_travlDil .content_flightht_travel_dil {
  max-height: 30%;
  overflow: auto;
}
/* Shubhangi */
.flowsHeading {
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 6px;
}
.flowsContent {
    font-size: 14px;
}
p.flowsContent {
    margin-bottom: 6px;
}
.flowsContent a {
    font-size: 14px;
    font-weight: normal;
    color: #0d6efd;
    text-decoration: underline;
}
.ul_margin_bottom {
    margin-bottom: 6px;
}
.briefingRemember {
    margin-top: 10px;
}
.flowsSecondPart {
    border: 0px !important;
}
.tabsContentHeading {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 6px;
}
.tabContent {
    font-size: 14px;
}
.tabContent a{
    font-size: 14px;
    font-weight: normal;
    color: #0d6efd;
    text-decoration: underline;
}
.top_tips {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 10px;
}
.VVPM_job_label {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.popup_flight_travlDil {
    max-height: 275px;
    font-weight: normal;
}
.popup_overflow {
    overflow-y: scroll;
}
a {
	box-shadow: 0 0 0 0 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.close_flight_travelDl {
    text-decoration: none !important;
}
.vertical_tabs {
    width: 150px !important;
  }
.faqsQuesHeading {
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 6px;
}
#v-pills-tabContent {
    width: 100%;
}
.vertical_tabs .nav-link {
    background-color: #ffffff !important;
    color: #305698 !important;
    text-align: left !important;
    border: 1px solid #cccccc;
    border-radius: 0px !important;
    font-size: 16px;
    width: 150px !important;
}
.vertical_tabs .nav-link:hover {
    border-radius: 0px !important;
    font-weight: bold;
    font-size: 16px;
}
.vertical_tabs .nav-link.active {
    border-radius: 0px !important;
    background-color: #305698 !important;
    color: #ffffff !important;
    text-align: left !important;
    border: 1px solid #305698;
    font-size: 16px;
}

.tabs_box {
    max-height: 215px;
    background-color: #d1e7fc;
    opacity: 100% !important;
    color: #212529;
    padding: 20px;
    border-top: 3px solid #2F5597;
    overflow-y: auto;
}
#tab_panes {    
    border: 0px;
    margin-bottom: 1px;
}

#tab_panes .nav-item {    
    border: 0px;
    box-shadow: 5px 0px 10px #ABABAB !important;
    margin-right: 6px;
}

#tab_panes .nav-link {    
    background-color: #ffffff;
    color: #2F5597;
    border-radius: 0px !important; 
    text-decoration: none !important;
    text-align: center;
    border: 0px;
    border-top: 1px solid #bbbbbb;
}

#tab_panes .nav-link.active {    
    background-color: #2F5597;
    color: #ffffff; 
    border: 1px solid #2F5597;
}

#tab_panes .nav-link:hover {  
    font-weight: bold;
}
.phase1_accordion {
    background-color: #a3ceff !important; 
}

.phase2_accordion {
    background-color: #6FB2FF !important; 
}

.phase3_accordion {
    background-color: #07DABE !important; 
}

.phase4_accordion {
    background-color: #FED62F !important; 
}

.phase5_accordion {
    background-color: #FDA24B !important; 
}



/*BE-1*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');



*, *:after, *:before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
}


*:focus {
	outline: none;
}



details div {
	
	padding: 0.5em 0.5em 0.5em 0.5em ;
}

details div > * + * {
	margin-top: 0;
}

details + details {
	margin-top: .5rem;
}

summary {
	list-style: none;
}

summary::-webkit-details-marker {
	display: none;
}

summary {
	border: 2px solid #bebfc1;
	padding: .75em 1em;
	cursor: pointer;
	position: relative;
	padding-left: calc(1.75rem + .75rem + .75rem);
  border-radius:4px ;
}

summary:before {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: .75rem;
	content: "↓";
	width: 1.75rem;
	height: 1.75rem;
	background-color: #171717;
	color: #FFF;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
  border-radius: 4px;
}

details[open] summary {
	background-color: #eee;
}

details[open] summary:before {
	content: "↑";
}

summary:hover {
	background-color: #eee;
}

a {
	color: inherit;
	font-weight: 600;
	text-decoration: none;
	box-shadow: 0 1px 0 0;
}

a:hover {
	box-shadow: 0 3px 0 0;
}

code {
	font-family: monospace;
	font-weight: 600;
}

/*Tabs*/
.tabs_class{ border-radius:5px;}


.tabs {
	display: flex;
	flex-wrap: wrap; 
	
}
.tabs label {
	order: 1; 
	display: block;
	padding: 1rem 1.38rem;
	margin-right: 0.1rem;
	cursor: pointer;
  background: #90CAF9;
  transition: background ease 0.2s;
}


.tabs .tab {
  order: 99; 
  flex-grow: 1;
	width: 100%;
	display: none;
  padding: 1rem;
  background: #fff;
}
.tabs input[type="radio"] {
	display: none;
}
.tabs input[type="radio"]:checked + label {
	background: #fff;
}
.tabs input[type="radio"]:checked + label + .tab {
	display: block;
	
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}



/*****color****/
#tabone-content {
color: #fff;
/*background-color: #3dc497;*/
	background: rgb(65,116,205);
background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);
border-bottom-left-radius: 5px;	
border-top-left-radius: 5px;		
font-size: 17px;
font-weight: 600;
}

#tabone-content-1 {
color: #333;
box-shadow: 1px 3px 3px #dee2e6;

border-radius: 5px;	
margin-top:0px;
background-color: #d1e7fc;
	
}

#tabtwo-content {
  color: #fff;
  /*background: #bbc605;*/
	background: rgb(128,187,255);
background: linear-gradient(180deg, rgba(128,187,255,1) 0%, rgba(58,150,255,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabtwo-content-2 {
  color: #333;
  background: #fff;
  
  
  border-radius: 5px;	
  margin-top:0px;	
  background-color: #ebf5fe;	
	box-shadow: 1px 3px 3px #dee2e6;
	
	
}

#tab3-content {
  color: white;
  /*background: #ff9a26;*/
	background: rgb(16,236,206);
background: linear-gradient(180deg, rgba(16,236,206,1) 0%, rgba(0,167,144,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tab3-content-1 {
  color: #333;
  background: #e5fcfb;
 
 box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;	
  margin-top: 0px;	
 
}

#tabfour-content {
  color: #fff;
  /*background: #00c2cc;*/
	background: rgb(254,223,57);
background: linear-gradient(180deg, rgba(254,223,57,1) 0%, rgba(238,193,11,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabfour-content-4 {
  color: #333;
  background: #f7fbe1;
 box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;
  margin-top: 0px;	
}

#tabfive-content {
  color: #fff;
  background: rgb(118,214,213);
background: linear-gradient(180deg, rgba(118,214,213,1) 0%, rgba(66,178,176,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabfive-content-1 {
  color: #333;
  background: #d3efef;
  box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 10px;
}

#tabsix-content {
  color: #fff;
  /*background: #5fb1ff;*/
  background: rgb(255,185,119);
background: linear-gradient(180deg, rgba(255,185,119,1) 0%, rgba(250,137,31,1) 100%);	
  border-bottom-right-radius: 5px;	
  border-top-right-radius: 5px;	
  font-size: 17px;
font-weight: 600;
}

#tabsix-content-1 {
  color: #333;
  background: #fff3d4;
  box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;
  margin-top:0px;
}


.pr{background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);}
.vvpm{background: linear-gradient(180deg, rgba(128,187,255,1) 0%, rgba(58,150,255,1) 100%);}
.map{background: linear-gradient(180deg, rgba(16,236,206,1) 0%, rgba(0,167,144,1) 100%);}
.mm{background: linear-gradient(180deg, rgba(254,223,57,1) 0%, rgba(238,193,11,1) 100%);}
.mw{background: linear-gradient(180deg, rgba(255,185,119,1) 0%, rgba(250,137,31,1) 100%);}




/*Temp Styles for IVA*/
.breadcrumb{
  background-color: white;
}
.shape #rect1{
  cursor: pointer;
  
}
.shape #rect2{
  cursor: pointer;
}
.shape:hover {
  fill: blue;
}
.shape{
  background-color: #ebf5fe !important;
  padding: 20px;
  width:100%;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

/*Accordion 1*/
.planningrow{
  width:100%;

}
.HLPaccordion1 .accordionTitle{
  background-color: #2F5597;
  border-radius:5px;

}

.HLPaccordion1 .accordionContent{

}

.HLPdiagram1{
  float:left;
  width:30%;
}
.HLPcontent1{
  float:left;
  width:70%;
  height:100%;
}

.ConceptMeeting{
  background-color:white ;
}
.PlanningPrioritisation{
  background-color:white ;
}

.HighLevelProcessesIntro{
  margin-top:3%;
}
.HLPURL{
  color:#4472c4;
}

.HLPContent{
  padding:3%;
}