body{margin: 0px; padding: 0px;}
.h1-font {
    font-weight: normal !important;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.be_wrapper{ width: 100%; height: auto; margin: 0px auto;}
.header_strip {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);
    padding: 15px 0px;
    opacity: 0.9;
}

.cm_font{font-size: 13px;}

.comman_hd{font-weight: 600 !important;}

.bg_gradient_box{background-color: #A30F12; width: 100%; height: 10px;}
.bg_accordian{background-color: #fff;  border-radius: 10px; }
.comman_width{width: 55%; margin: auto; padding: 10px;}
.comman_width_d{width: 35%; margin: auto;}
.comman_width_de{width: 100%; margin: auto;}
.comman_width_e{width: 55%; margin: auto;}

.bg_purple{background-color: #b0b0f0;}
.bg_green{background-color: #b8dcc6;}
.bg_pink{background-color: #dcb8b8;;}
.bg_yellow{background-color: #dcdbb8;;}
.bg_red{background-color: #f75990;}
.bg_grey{background-color: #92A8D1;}

.brief{  border-radius: 5px; border-right: 3px solid #fff;}
.planning{  border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.approval{   border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.material{   border-radius: 5px; border-right: 3px solid #fff; padding: 10px;}
.widthdrawal{  border-radius: 5px; border-right: 3px solid #fff;}


.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  background: #ebf5fe;
}

.bg-footer {
  background: #4f4f4f !important;
  color:#fff;
}

.comman_accordian{font-size: 18px !important;
  color: #fff !important;
  font-style: italic !important;
  font-weight: 600 !important;
}

.accordion-body {

    padding-top: 2px !important;
    padding-bottom: 15px !important;
}
.h5, h5 {
    font-size: 1rem !important;
}

.ul_padding{padding-left: 1rem;}



.p_font{ font-size: 14px;}


svg path{ strocke:#eee;
 stroke-width: .25;}

svg path:hover{transition: 0.6s;
               cursor: pointer;
fill:#edeef0; color: #000000;}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.accordion-button:focus {
    box-shadow: none !important;
}

.comman_font_pharasize{ font-size: 11px;}

.comman_border-radius{border-radius: 5px;}
.widthdrawal_content{background-color: #f7f7f7;}
.widthdrawal_content_2{background-color: #f0f1f1;}

.btm_footer{ }
.btm_footer ul{ margin: 0px; padding: 0px; list-style: none;}
.btm_footer ul li{ float: left; padding: 0px 0px 0px 50px; text-decoration: none !important; font-weight: normal;}
.btm_footer ul li a{ float: left; padding: 0px 10px 0px 10px; text-decoration: none !important; font-weight: normal;}
.btm_footer ul li a:hover{ float: left; padding: 0px 10px 0px 10px; text-decoration: none !important; font-weight: normal;}


.tab div {
    display: none;
}

.tab div:target {
    display: block;
  
}

.tab-2 div {
    display: none;
}

.tab-2 div:target {
    display: block;
	height: 30px;
  
}

.pop{font-size: 12px; background-color: #cfe2ff; padding: 5px 10px 5px 10px; border-radius: 5px;}
.pop ul{margin: 0px; padding: 0px; list-style: none;}
.pop ul li{}



/*popup*/
.overlay_flight_traveldil {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 1s;
  visibility: hidden;
  opacity: 0;
}
.overlay_flight_traveldil:target {
  visibility: visible;
  opacity: 1;
}
.popup_flight_travlDil {
  margin: 215px 0px 0px 0px;

  padding: 20px 20px 10px 20px;
 /* background: #b8f5ed;*/
  
background-image: linear-gradient(135deg, #fdfcfb 10%, #e2d1c3 100%);
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 2s ease-in-out;
  border-left:5px solid #d62c27;	
}
.popup_flight_travlDil .close_flight_travelDl {
  position: absolute;
  top: 10px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none !important;
  color: #333;
}
.popup_flight_travlDil .content_flightht_travel_dil {
  max-height: 30%;
  overflow: auto;
}

.flowsHeading {
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 6px;
}
.flowsContent {
    font-size: 14px;
}
p.flowsContent {
    margin-bottom: 6px;
}
.flowsContent a {
    font-size: 14px;
    font-weight: normal;
    color: #4472c4;
    text-decoration: underline;
}
.ul_margin_bottom {
    margin-bottom: 6px;
}
.briefingRemember {
    margin-top: 10px;
}
.flowsSecondPart {
    border: 0px !important;
}
.tabsContentHeading {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 6px;
}
.tabsContentSubHeading {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 2px;
}
.tabsContentHeadline {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 6px 8px 0px;
}
.tabContent {
    font-size: 14px;
}
.tabContent a{
    font-size: 14px;
    font-weight: normal;
    color: #4472c4;
    text-decoration: underline;
}
.top_tips {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 10px;
}
.VVPM_job_label {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.popup_flight_travlDil {
    max-height: 275px;
    font-weight: normal;
    overflow-y: auto;
}
a {
	box-shadow: 0 0 0 0 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

.close_flight_travelDl {
    text-decoration: none !important;
}
.vertical_tabs {
    width: 150px !important;
    position: sticky;
    top: 0;
  }
.faqsQuesHeading {
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 6px;
}
.faqcontent{
    background-color: #F8F8F8
}
#v-pills-tabContent {
    width: 100%;
}
.vertical_tabs .nav-link {
    background-color: #ffffff !important;
    color: #305698 !important;
    text-align: left !important;
    border: 1px solid #cccccc;
    border-radius: 0px !important;
    font-size: 16px;
}
.vertical_tabs .nav-link:hover {
    border-radius: 0px !important;
    font-weight: bold;
    font-size: 16px;
}
.vertical_tabs .nav-link.active {
    border-radius: 0px !important;
    background-color: #305698 !important;
    color: #ffffff !important;
    text-align: left !important;
    border: 1px solid #305698;
    font-size: 16px;
}

.tabs_box {
  height: 240px;
    max-height: 240px;
    background-color: #d1e7fc;
    opacity: 100% !important;
    color: #212529;
    padding: 20px;
    border-top: 3px solid #2F5597;
    overflow-y: auto;
}

#tab_panes {    
    border: 0px;
    margin-bottom: 1px;
}

#tab_panes .nav-item {    
    border: 0px;
    box-shadow: 5px 0px 10px #ABABAB !important;
    margin-right: 6px;
}

#tab_panes .nav-link {    
    background-color: #ffffff;
    color: #2F5597;
    border-radius: 0px !important; 
    text-decoration: none !important;
    text-align: center;
    border: 0px;
    border-top: 1px solid #bbbbbb;
    width: 150px;
    height: 50px;
    font-size: 14px;
    padding: 4px 
}

.navLinkTab{
    width: 165px!important;
}

#tab_panes .nav-link.active {    
    background-color: #2F5597;
    color: #ffffff; 
    border: 1px solid #2F5597;
}

#tab_panes .nav-link:hover {  
    font-weight: bold;
}
.phase1_accordion {
    background-color: #a3ceff !important; 
}

.phase2_accordion {
    background-color: #6FB2FF !important; 
}

.phase3_accordion {
    background-color: #07DABE !important; 
}

.phase4_accordion {
    background-color: #FED62F !important; 
}

.phase5_accordion {
    background-color: #FDA24B !important; 
}



/*BE-1*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');



*, *:after, *:before {
	box-sizing: border-box;
}

:root {
	font-size: 16px;
}


*:focus {
	outline: none;
}



details div {
	
	padding: 0.5em 0.5em 0.5em 0.5em ;
}

details div > * + * {
	margin-top: 0;
}

details + details {
	margin-top: .5rem;
}

summary {
	list-style: none;
}

summary::-webkit-details-marker {
	display: none;
}

summary {
	border: 2px solid #bebfc1;
	padding: .75em 1em;
	cursor: pointer;
	position: relative;
	padding-left: calc(1.75rem + .75rem + .75rem);
  border-radius:4px ;
}

summary:before {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: .75rem;
	content: "↓";
	width: 1.75rem;
	height: 1.75rem;
	background-color: #171717;
	color: #FFF;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
  border-radius: 4px;
}

details[open] summary {
	background-color: #eee;
}

details[open] summary:before {
	content: "↑";
}

summary:hover {
	background-color: #eee;
}

a {
	color: inherit;
	font-weight: 600;
	text-decoration: none;
	box-shadow: 0 1px 0 0;
}

a:hover {
	box-shadow: 0 3px 0 0;
}

code {
	font-family: monospace;
	font-weight: 600;
}

/*Tabs*/
.tabs_class{ border-radius:5px;}


.tabs {
	display: flex;
	flex-wrap: wrap; 
	
}
.tabs label {
	order: 1; 
	display: block;
	padding: 1rem 1.38rem;
	margin-right: 0.1rem;
	cursor: pointer;
  background: #90CAF9;
  transition: background ease 0.2s;
}


.tabs .tab {
  order: 99; 
  flex-grow: 1;
	width: 100%;
	display: none;
  padding: 1rem;
  background: #fff;
}
.tabs input[type="radio"] {
	display: none;
}
.tabs input[type="radio"]:checked + label {
	background: #fff;
}
.tabs input[type="radio"]:checked + label + .tab {
	display: block;
	
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}



/*****color****/
#tabone-content {
color: #fff;
/*background-color: #3dc497;*/
	background: rgb(65,116,205);
background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);
border-bottom-left-radius: 5px;	
border-top-left-radius: 5px;		
font-size: 17px;
font-weight: 600;
}

#tabone-content-1 {
color: #333;
box-shadow: 1px 3px 3px #dee2e6;

border-radius: 5px;	
margin-top:0px;
background-color: #d1e7fc;
	
}

#tabtwo-content {
  color: #fff;
  /*background: #bbc605;*/
	background: rgb(128,187,255);
background: linear-gradient(180deg, rgba(128,187,255,1) 0%, rgba(58,150,255,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabtwo-content-2 {
  color: #333;
  background: #fff;
  
  
  border-radius: 5px;	
  margin-top:0px;	
  background-color: #ebf5fe;	
	box-shadow: 1px 3px 3px #dee2e6;
	
	
}

#tab3-content {
  color: white;
  /*background: #ff9a26;*/
	background: rgb(16,236,206);
background: linear-gradient(180deg, rgba(16,236,206,1) 0%, rgba(0,167,144,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tab3-content-1 {
  color: #333;
  background: #e5fcfb;
 
 box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;	
  margin-top: 0px;	
 
}

#tabfour-content {
  color: #fff;
  /*background: #00c2cc;*/
	background: rgb(254,223,57);
background: linear-gradient(180deg, rgba(254,223,57,1) 0%, rgba(238,193,11,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabfour-content-4 {
  color: #333;
  background: #f7fbe1;
 box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;
  margin-top: 0px;	
}

#tabfive-content {
  color: #fff;
  background: rgb(118,214,213);
background: linear-gradient(180deg, rgba(118,214,213,1) 0%, rgba(66,178,176,1) 100%);
font-size: 17px;
font-weight: 600;
}

#tabfive-content-1 {
  color: #333;
  background: #d3efef;
  box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 10px;
}

#tabsix-content {
  color: #fff;
  /*background: #5fb1ff;*/
  background: rgb(255,185,119);
background: linear-gradient(180deg, rgba(255,185,119,1) 0%, rgba(250,137,31,1) 100%);	
  border-bottom-right-radius: 5px;	
  border-top-right-radius: 5px;	
  font-size: 17px;
font-weight: 600;
}

#tabsix-content-1 {
  color: #333;
  background: #fff3d4;
  box-shadow: 1px 3px 3px #dee2e6;
  border-radius: 5px;
  margin-top:0px;
}

.web-image{
  max-width: 100%; 
  height: auto; 
  display: block; 
}

.pr{background: linear-gradient(180deg, rgba(65,116,205,1) 0%, rgba(39,70,124,1) 100%);}
.vvpm{background: linear-gradient(180deg, rgba(128,187,255,1) 0%, rgba(58,150,255,1) 100%);}
.map{background: linear-gradient(180deg, rgba(16,236,206,1) 0%, rgba(0,167,144,1) 100%);}
.mm{background: linear-gradient(180deg, rgba(254,223,57,1) 0%, rgba(238,193,11,1) 100%);}
.mw{background: linear-gradient(180deg, rgba(255,185,119,1) 0%, rgba(250,137,31,1) 100%);}


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

/*Accordion 1*/
.planningrow{
  width:100%;

}
.accordion1 .accordionTitle{
  background-color: #a3ceff ;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion1 .accordionContent{
  background-color: #ebf5fe ;
}

.ibuShape1 {
  background-color: #ebf5fe!important;
  padding: 20px;
  width: 85%;
}


/*Accordion 2*/
.accordion2 .accordionTitle{
  background-color: #6FB2FF ;
  width:100%;
  border:none;

}
.accordion2 .accordionContent{
  background-color: #ebf5fe ;
}
.diagram2{
  float:left;
  width:30%;
}
.content2{
  float:left;
  width:70%;
  height:100%;
}
.ibuShape2 {
  background-color: #ebf5fe !important;
  padding: 20px;
  width: 85% !important;
}

/*Accordion 3*/
.accordion3 .accordionTitle{
  background-color: #07DABE !important;
  border:none;

}
.accordion3 .accordionContent{
  background-color: #ebf5fe ;
}

.diagram3{
  float:left;
  width:30%;
}
.content3{
  float:left;
  width:70%;
  height:100%;
}
.ibuShape3{
  background-color: #ebf5fe !important;
  padding: 20px;
  width:85%;
}

/*Accordion 4*/
.accordion4 .accordionTitle{
  background-color: #FED62F !important ;
  border:none;
}
.accordion4 .accordionContent{
  background-color: #ebf5fe ;
}

.diagram4{
  float:left;
  width:30%;
}
.content4{
  float:left;
  width:70%;
  height:100%;
}

.ibuShape4 {
  background-color: #ebf5fe !important;
  padding: 5px;
  width: 100% !important;
  /* margin-left: 5% !important; */
}


/*Accordion 5*/
.accordion5 .accordionTitle{
  background-color: #FDA24B !important ;
  width:100%;
  border:none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accordion5 .accordionContent{
  background-color: #ebf5fe ;
}

.diagram5{
  float:left;
  width:30%;
}
.content5{
  float:left;
  width:70%;
  height:100%;
}
.privacyShape5{
  background-color: #ebf5fe !important;
  padding: 20px;
  width:100%;
}
.TabsHeight {
  height: 295px !important;
  max-height: 500px !important;
}
a {
  color: #4472c4;
  font-weight: normal;
}

.accordion2 .pop-heading{
  font-size: 12px; 
  background-color: #ebf5fe; 
  padding: 5px 10px 5px 10px; 
  border-radius: 2px;
  border:1px solid #d4d8e0;
  box-shadow: 0px 2px 8px #cfe2ff;
  border-radius: 5px;	
  font-weight: 500;
}
.accordion2 .card-content{
  font-size:10px!important;
}
.accordion2 .card-body-title{
  background-color: #6FB2FF;
}
.accordion2 .card-links{
  color: #4b82c1;
  border: 1px solid #4277b5;
}
.accordion2 .card-links:hover{
  background-color: #6FB2FF;
  color: #fff;
}

.accordion-heading{
  font-size: 12px; 
  padding: 1rem 1.5rem 0.5rem 1.5rem; 
  border-radius: 5px;
  border:1px solid #d4d8e0;
  box-shadow: 0px 0.5px 4px #cfe2ff;
  font-weight: 500;
  font-style: italic;
}

.accordion-heading p{
  margin-bottom: 0.5rem;
}