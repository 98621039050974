.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh!important;
}

main {
 flex: 1 0 auto;
}

#navBar {
  background-color: #2F5597;
  display: block !important;
}

#navBar li.nav-item {
  display: inline-block !important;
}

.logoImage {
  padding: 8px;
  padding-left: 20px;
}

#navBar .nav-link {
  background-color: #2F5597;
  color: #ffffff !important;
  border: 0px;
  border-radius: 0px !important;
  text-decoration: none !important;
  font-weight: normal;
  box-shadow: none !important;
}

#navBar .nav-link:hover {
  background-color: #4e6fa9;
  color: #ffffff !important;
  border: 0px;
  border-radius: 0px !important;
  text-decoration: none !important;
  font-weight: bold;
}

/*#navBar .nav-link:hover {
  border: 0px;
  border-radius: 0px !important;
  text-decoration: underline !important;
  font-weight: bold;
}*/
#navBar .nav-link:active {
  background-color: #4e6fa9;
  color: #ffffff !important;
  border: 0px;
  border-radius: 0px !important;
  text-decoration: none !important;
  font-weight: bold;
}

#navBar .nav-link.active {
  border: 0px;
  text-decoration: underline !important;
  font-weight: bold;
}

.userNamePlaceholder {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  display: inline-block;
  padding: 20px;
  padding-top: 20px;
  text-shadow: 1px 1px 3px black;
}
.user {
  margin-left: auto;
}
.bg-footer{
  margin-top:5%;
  position: relative;
}
#navBar .dropdown-toggle:active {
  font-weight: bold;
  text-decoration: underline !important;
}

#navBar .dropdown-toggle.show {
  font-weight: bold;
  text-decoration: underline !important;
}

#navBar .dropdown-menu {
  padding: 0px;
  border: 1px solid #4e6fa9;
  width: 285px;
}
#navBar .dropdown-item {
  font-size: 14px;
  background-color: #2F5597 !important;
  color: #ffffff !important;
  padding: 10px;
  text-decoration: none !important;
}
#navBar .dropdown-item:hover {
  font-weight: bold;
  background-color: #4e6fa9 !important;
  color: #ffffff !important;
  border: 1px solid #4e6fa9;
}

.ul_list{padding-left: 0px;list-style-type: none !important}
.list-styled-disc {list-style: disc !important;}

.pageHeading {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.pageSubHeading {
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
}

.tactic-text{
  font-style: italic;
  text-align: end;
  margin-top: 2px;
  padding-right: 9.5%;
}
.HomeIntro{
  margin-top:2%;
}
.material_lifecycle_flow {
  width:90%;
  height:90%;
}
.material_lifecycle_steps {
  width:90%;
  height:90%;
  margin-left: 4.5%;
  padding-left: 0px;
}
#hometab .headerCard {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  border-radius: 0px;
  box-shadow: 3px -3px 3px #cccccc;
  width: 90%;
  z-index: 20;
  margin-left: 4.5%;
}
#hometab .tacticsContent {
  width: 100%;
  border-radius: 0px;
  box-shadow: 3px 3px 3px #cccccc;
  background-color: #F2F2F2;
  margin-top: -3%;
  padding-top: 2.5%;
  margin-bottom: 20%;

}

.tacticsSubHeading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.tacticsBlocks {
  padding: 10px;
  text-align: center;
}

.tacticsInnerBlock {
  background-color: #2F5597;
  display: block;
  padding: 10px;
  width: 115px;
  height: 113px;
  text-decoration: none !important;
  font-weight: normal;
}

.tacticsInnerBlock:hover {
  background-color: #4e6fa9;
  font-weight: bold;
}

.imageRow {
  padding: 0px;
  vertical-align: top;
  background-color: transparent !important;
}

.titleRow {
  padding: 0px;
  vertical-align: bottom;
  background-color: transparent !important;
}

.tacticsCell {
  padding: 0px;
  background-color: transparent;
  text-align: center;
  border: 0px;
}

.tacticsIcons {
  width: auto;
  height: 55px;
}

.tacticTitle {
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
  margin-top: 10px;
}

.tacticsDisabled {
  cursor: default;
  background-color: #aaaaaa;
}

.tacticsDisabled:hover {
  background-color: #aaaaaa;
  font-weight: normal;
}

@media (min-width: 1200px) {
  .otherMaterials {
      padding-left: 9%; 
  }
}

.faqAccordion{
  margin-top:1%;
  margin-bottom:1%;
}
.faqAccordion .accordionTitle{
  background-color: white ;
  color:black !important;
  border: 1px solid #d3d3d3;
  border-radius:5px;
  text-align: start;
}
.faqAccordion .accordionContent{

}
.faqcontent{
  padding:3%;
}
.CoreURL{
  color:#4472c4;
}
#user_name{
  color:#ffffff;
}
.URLColor{
  color:#4472c4;
}
.right-corner-item {
  float: right!important;
  padding-top: 18px;
}

.dropend:hover .dropdown-menu {
  display: block;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(286px, -15.3333px, 0px);
}

.dropend .dropdown-item {
  display: flex;
  align-items:center;
  justify-content:space-between;
} 


#cookieWidgetLink a{
  color: #fff;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

#cookieWidgetLink a:hover{
  color: #F2F2F2;
}
